import { Select } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import { UsePaymentAccountsQuery } from '../../payment-account/queries'
import buildPaymentAccountOptions from '../../payment-account/utils/build-payment-account-options'
import ContractSubscriptionPeriodInput from './contract-subscription-period-input'
import ContractInvoiceEndDateInput from './contract-invoice-end-date-input'

interface Props {
  isUpdate?: boolean
}

export default function ContractGeneralInformationsForm({ isUpdate }: Props) {
  const { t } = useTranslation()
  const { id: customerId } = useParams()

  const {
    control, formState: { errors }, watch,
  } = useFormContext<UpsertContractAttributes>()

  const { data: paymentAccountOptions } = UsePaymentAccountsQuery(customerId, { select: buildPaymentAccountOptions })

  const paymentAccountId = watch('paymentAccountId')

  return (
    <div className="flex flex-col overflow-auto mt-2 mb-4">
      <div className="w-full flex flex-col gap-4">
        <Controller
          name="paymentAccountId"
          control={control}
          rules={{ required: true }}
          defaultValue={undefined}
          render={({ field }) => (
            <Select
              {...field}
              className="w-full"
              label={t('customer:payment-account:name') as string}
              placeholder={t('customer:payment-account:add-payment-account') as string}
              value={paymentAccountId || null}
              data={paymentAccountOptions || []}
              error={errors.paymentAccountId && errors.paymentAccountId?.message}
              required
              searchable
              nothingFoundMessage={t('common:input.not-found') as string}
            />
          )}
        />
        <ContractSubscriptionPeriodInput isUpdate={isUpdate} />
        <ContractInvoiceEndDateInput isUpdate={isUpdate} />
      </div>
    </div>
  )
}
