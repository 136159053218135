import { DateInput, DateInputProps } from '@mantine/dates'
import { BiCalendar } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox } from '@mantine/core'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import IMask from 'imask/holder'
import { useState } from 'react'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import LockButton from '../../../shared/components/form/lock-button'

dayjs.extend(customParseFormat)

interface Props {
  isUpdate?: boolean
}

export default function ContractSubscriptionPeriodInput({ isUpdate }: Props) {
  const { t, i18n } = useTranslation()

  const {
    control, setValue, watch,
  } = useFormContext<UpsertContractAttributes>()

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const [contractWithoutEndDate, setContractWithoutEndDate] = useState(!endDate && !!isUpdate)
  const [isLocked, setIsLocked] = useState(!!isUpdate)

  const dateFormat = i18n.language === 'pt' ? 'DD/MM/YYYY' : 'MM/DD/YYY'

  const dateParser: DateInputProps['dateParser'] = (input) => dayjs(input, dateFormat).toDate()

  const maskConfig = {
    mask: '00/00/0000',
  }

  const handleCheckContractWithoutEndDate = (checked: boolean) => {
    setContractWithoutEndDate(checked)

    if (!checked) {
      setValue('startDate', startDate)
      const newEndDate = new Date(startDate)
      newEndDate.setDate(startDate.getDate() + 1)
      setValue('endDate', newEndDate)
    }

    if (checked) {
      setValue('startDate', startDate)
      setValue('endDate', null)
    }
  }

  return (
    <div className={`flex w-full rounded-lg px-4 pt-2 pb-4 ${isUpdate && isLocked ? 'bg-gray-50' : 'border-[1px] border-gray-200'}  gap-4`}>
      <div className="w-full">
        <div className="flex gap-4">
          <div className="w-8/12">
            {contractWithoutEndDate ? (
              <Controller
                name="startDate"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    required
                    placeholder={t('customer:contract:start-date') as string}
                    label={t('customer:contract:start-date') as string}
                    rightSection={(<BiCalendar className="cursor-pointer" size={18} />)}
                    popoverProps={{ withinPortal: false }}
                    locale={i18n.language === 'pt' ? 'pt-br' : 'en'}
                    valueFormat={dateFormat}
                    dateParser={dateParser}
                    disabled={isLocked}
                    ref={(el) => {
                      if (!el) return
                      IMask(el, maskConfig)
                    }}
                  />
                )}
              />
            ) : (
              <div className="flex gap-4">
                <Controller
                  name="startDate"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      required
                      placeholder={t('customer:contract:start-date') as string}
                      label={t('customer:contract:start-date') as string}
                      rightSection={(<BiCalendar className="cursor-pointer" size={18} />)}
                      popoverProps={{ withinPortal: true }}
                      locale={i18n.language === 'pt' ? 'pt-br' : 'en'}
                      valueFormat={dateFormat}
                      dateParser={dateParser}
                      maxDate={endDate ?? undefined}
                      disabled={isLocked}
                      ref={(el) => {
                        if (!el) return
                        IMask(el, maskConfig)
                      }}
                    />
                  )}
                />
                <Controller
                  name="endDate"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      required
                      placeholder={t('customer:contract:end-date') as string}
                      label={t('customer:contract:end-date') as string}
                      rightSection={(<BiCalendar className="cursor-pointer" size={18} />)}
                      popoverProps={{ withinPortal: true }}
                      locale={i18n.language === 'pt' ? 'pt-br' : 'en'}
                      valueFormat={dateFormat}
                      dateParser={dateParser}
                      disabled={isLocked}
                      minDate={startDate}
                      ref={(el) => {
                        if (!el) return
                        IMask(el, maskConfig)
                      }}
                    />
                  )}
                />
              </div>
            )}
          </div>

          <div className="flex w-60 mt-8">
            <Checkbox
              color="violet"
              label={t('customer:contract:automatic-renewal') as string}
              checked={contractWithoutEndDate}
              onChange={(event) => handleCheckContractWithoutEndDate(event.currentTarget.checked)}
              disabled={isLocked}
            />
          </div>

          {isUpdate && (
          <LockButton isLocked={isLocked} setIsLocked={setIsLocked} />
          )}

        </div>
      </div>
    </div>
  )
}
