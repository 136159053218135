import { Button } from '@mantine/core'
import Breadcrumbs from './breadcrumbs-v2'

interface PageHeaderProps {
  buttonText?: string
  buttonOnClick?: () => void
}

export default function PageHeader({ buttonText, buttonOnClick }: PageHeaderProps) {
  return (
    <div className="w-full border-b flex-row fixed z-50 bg-white">
      <div className="my-3 px-10 flex-row flex items-center justify-between">
        <Breadcrumbs />
        {buttonText && buttonOnClick && (
          <Button size="xs" onClick={buttonOnClick}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  )
}
