import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { PaymentAccount } from '../../../models'
import Typography from '../../../shared/components/general/typography'
import TableMenuCell from '../../../shared/components/table/table-menu-cell'
import { formatTaxIdForDisplay } from '../utils/formatters'
import UpdatePaymentAccountModal from './update-payment-account-modal'
import useDeletePaymentAccountHandler from './modal-actions/delete-payment-account-modal'

interface Props {
  paymentAccount: PaymentAccount;
}

export default function PaymentAccountCard({ paymentAccount }: Props) {
  const { id: customerId } = useParams()

  const deletePaymentAccount = useDeletePaymentAccountHandler(customerId!)

  const [isUpdatePaymentAccountModalOpen, setIsUpdatePaymentAccountModalOpen] = useState(false)

  return (
    <div className="flex border border-gray-400 rounded w-[calc(100vw-380px)] p-4 justify-between items-center">
      <UpdatePaymentAccountModal
        opened={isUpdatePaymentAccountModalOpen}
        onClose={() => setIsUpdatePaymentAccountModalOpen(false)}
        paymentAccount={paymentAccount}
      />
      <div className="w-11/12">
        <Typography size="md" fw={400}>
          {paymentAccount.tradeName || paymentAccount.businessName}
        </Typography>

        <Typography className="text-gray-600" size="sm" fw={400}>
          {formatTaxIdForDisplay(paymentAccount.taxId)}
          {paymentAccount.email ? ' • ' : ''}
          {paymentAccount.email}
        </Typography>
      </div>
      <TableMenuCell
        name={paymentAccount.tradeName || paymentAccount.businessName}
        color="gray"
        onDelete={() => deletePaymentAccount(paymentAccount)}
        onEdit={() => setIsUpdatePaymentAccountModalOpen(true)}
      />
    </div>
  )
}
