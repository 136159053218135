import Payment from '../../../models/payment'
import { PaymentStatus } from '../enum/payment-status'

export const isPaymentFileAvailable = (payment: Payment | null): boolean => {
  if (!payment) return false

  const unavailablePaymentStatus = [
    PaymentStatus.Failed,
    PaymentStatus.Canceled,
    PaymentStatus.Expired,
  ]

  return !unavailablePaymentStatus.includes(payment.status)
}
