import ContractDueOffsetDaysInput from './contract-payment-due-offset-days-input'
import ContractPaymentOffsetDaysInput from './contract-payment-offset-days-input'

interface Props {
  isUpdate?: boolean
}

export default function ContractPaymentScheduleForm({ isUpdate }: Props) {
  return (
    <div className="flex flex-col overflow-auto mt-2 mb-4">
      <div className="w-full flex flex-col gap-4">
        <ContractPaymentOffsetDaysInput isUpdate={isUpdate} />
        <ContractDueOffsetDaysInput isUpdate={isUpdate} />
      </div>
    </div>
  )
}
