import { NumberInput } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import Typography from '../../../shared/components/general/typography'
import LockButton from '../../../shared/components/form/lock-button'

interface Props {
  isUpdate?: boolean
}

export default function ContractPaymentOffsetDaysInput({ isUpdate }: Props) {
  const { t } = useTranslation()

  const [isLocked, setIsLocked] = useState(!!isUpdate)

  const { control } = useFormContext<UpsertContractAttributes>()

  return (
    <div className={`flex w-full rounded-lg px-4 pt-2 pb-4 ${isUpdate && isLocked ? 'bg-gray-50' : 'border-[1px] border-gray-200'}  gap-4`}>
      <div className="w-full">
        {isUpdate ? (
          <Controller
            name="paymentGatewaySettings.paymentOffsetDays"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <NumberInput
                label={t('customer:contract:form-sections:payment-offset-days')}
                placeholder={t('customer:contract:form-sections:payment-offset-days') as string}
                inputWrapperOrder={['label', 'input', 'description', 'error']}
                min={0}
                disabled={isLocked}
                required
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            name="paymentGatewaySettings.paymentOffsetDays"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <NumberInput
                required
                label={t('customer:contract:form-sections:payment-offset-days')}
                placeholder={t('customer:contract:form-sections:payment-offset-days') as string}
                min={0}
                {...field}
              />
            )}
          />
        )}
      </div>

      <div className="flex w-full mt-8 gap-2">
        <Typography>
          {t('customer:contract:form-sections:payment-offset-days-label')}
        </Typography>
      </div>

      {isUpdate && (
      <LockButton isLocked={isLocked} setIsLocked={setIsLocked} />
      )}

    </div>
  )
}
