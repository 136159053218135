import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import ContractPlansForm from './contract-plans-form'
import CollapseForm from '../../../shared/components/form/collapse-form'
import CollapseFormSection from '../../../shared/components/form/collapse-form-section'
import ContractGeneralInformationsForm from './contract-general-informations-form'
import ContractPaymentScheduleForm from './contract-payment-schedule-form'
import { useAuthTenant } from '../../auth/store/auth-store'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import ContractOmieIntegrationForm from './contract-omie-integration-form'
import { ExtendedContract } from '../../../models'

interface Props {
  updatedContract?: ExtendedContract
}

enum Tabs {
  GENERAL_INFORMATION = 'general-information',
  PLANS = 'plans',
  PAYMENT_SCHEDULE = 'payment-schedule',
  INTEGRATIONS = 'integrations',
}

export default function ContractForm({ updatedContract }: Props) {
  const { t } = useTranslation()
  const tenant = useAuthTenant()

  const isPaymentGatewayEnabled = tenant.externalServices.paymentGateway.enabled
  const isOmieEnabled = tenant.externalServices.omie.enabled

  const [activeSection, setActiveSection] = useState<Tabs>(Tabs.GENERAL_INFORMATION)

  const handleSectionChange = (section: string | null) => {
    if (Object.values(Tabs).includes(section as Tabs)) setActiveSection(section as Tabs)
  }

  const { watch } = useFormContext<UpsertContractAttributes>()

  const formValues = watch()

  const isGeneralInformationComplete = useMemo(
    () => (!!formValues.paymentAccountId
    && !!formValues.startDate
    && !!formValues.billingEndDay),
    [formValues],
  )

  const isContractPlansComplete = useMemo(
    () => !!formValues.plans?.length,
    [formValues],
  )

  const isPaymentScheduleComplete = useMemo(
    () => ((!!formValues.paymentGatewaySettings?.paymentOffsetDays
      || formValues.paymentGatewaySettings?.paymentOffsetDays === 0)
    && !!formValues.paymentGatewaySettings?.dueOffsetDays),
    [formValues],
  )

  const isContractIntegrationsComplete = useMemo(
    () => !!formValues.omieDetails?.contractNumber || !!updatedContract?.processorId,
    [formValues, updatedContract?.processorId],
  )

  return (
    <CollapseForm activeSection={activeSection} setActiveSection={handleSectionChange}>

      <CollapseFormSection
        value={Tabs.GENERAL_INFORMATION}
        isSectionComplete={isGeneralInformationComplete}
        sectionTitle={t('customer:contract:form-sections:general-information')}
      >
        <ContractGeneralInformationsForm isUpdate={!!updatedContract} />
      </CollapseFormSection>

      <CollapseFormSection
        value={Tabs.PLANS}
        isSectionComplete={isContractPlansComplete}
        sectionTitle={t('customer:contract:form-sections:plans')}
      >
        <ContractPlansForm />
      </CollapseFormSection>

      {isPaymentGatewayEnabled && (
        <CollapseFormSection
          value={Tabs.PAYMENT_SCHEDULE}
          isSectionComplete={isPaymentScheduleComplete}
          sectionTitle={t('customer:contract:form-sections:payment-schedule')}
        >
          <ContractPaymentScheduleForm isUpdate={!!updatedContract} />
        </CollapseFormSection>
      )}

      {isOmieEnabled && (
        <CollapseFormSection
          value={Tabs.INTEGRATIONS}
          isSectionComplete={isContractIntegrationsComplete}
          sectionTitle={t('customer:contract:form-sections:integrations:section-title')}
        >
          <ContractOmieIntegrationForm updatedContract={updatedContract} />
        </CollapseFormSection>
      )}

    </CollapseForm>
  )
}
