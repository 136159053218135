import { Text, TextInput, Textarea } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { MAX_TEXT_FIELD_LENGTH, MAX_LONG_TEXT_FIELD_LENGTH } from '../../../shared/utils/constants'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import CentsInput from '../../../shared/components/form/cents-input'
import SelectCreatable from '../../../shared/components/combo-box/select-creatable'
import { Product } from '../../../models'
import { productsKeys, useCreateProduct, useProductsQuery } from '../queries/product-queries'
import queryClient from '../../../config/query-client'
import Tooltip from '../../../shared/components/form/tooltip-input'

export default function PlanForm() {
  const { t } = useTranslation(['common', 'plan'])

  const {
    getValues,
    setValue,
    register,
    resetField,
    control,
    formState: { errors, isDirty },
  } = useFormContext<UpsertExtendedPlanAttributes>()

  const { data: productsData } = useProductsQuery({
  })

  const { mutate } = useCreateProduct()

  useEffect(() => {
    if (productsData?.length === 1 && !isDirty) {
      setValue('product', productsData[0])
    }
  }, [productsData, isDirty, setValue])

  return (
    <div>
      <TextInput
        className="mb-4"
        label={t('plan:name') as string}
        placeholder={t('plan:name') as string}
        error={errors.name && errors.name.message}
        required
        {...register('name', {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />

      <Textarea
        className="mb-4"
        label={t('plan:description') as string}
        placeholder={t('plan:description') as string}
        autosize
        error={errors.description && errors.description.message}
        {...register('description', {
          maxLength: { value: MAX_LONG_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />

      <CentsInput
        className="w-full mb-4"
        label={`${t('plan:fixed-amount') as string}`}
        placeholder={t('common:form-placeholder:decimal-zero') as string}
        error={errors.planSettings && errors.planSettings.fixedAmount?.message}
        value={getValues('planSettings.fixedAmount')}
        onChange={(value: number) => setValue('planSettings.fixedAmount', value as number)}
      />
      <Controller
        name="product"
        rules={{ required: true }}
        control={control}
        render={({ field }) => {
          const handleProductSelect = (productName: string) => {
            const productOption = productsData?.find((product) => product.name === productName)
            if (productOption) {
              field.onChange(productOption)
            } else {
              toast.error(t('messages:error.default'))
            }
          }

          const handleProductCreation = (value: string) => {
            const newProduct = { id: uuidv4(), name: value }
            field.onChange(newProduct)

            // Update the query cache immediately with the new product
            queryClient.setQueryData<Product[]>(productsKeys.lists(), (products) => {
              if (!products) return [newProduct]

              return [newProduct, ...products]
            })

            mutate({ name: value }, {
              onSuccess: (product) => {
                setValue('product', product)
              },
              // Remove the new product from the query cache if the API call fails
              onError: () => {
                queryClient.setQueryData<Product[]>(productsKeys.lists(), (products) => products?.filter((product) => product.name !== value) || [])
                toast.error(t('messages:error.default'))
                resetField('product')
              },
            })
          }

          return (
            <SelectCreatable
              {...field}
              placeholder={t('plan:add-or-chose-product')}
              label={(
                <div className="flex gap-[6px]">
                  <Text fw="500">
                    {t('plan:product')}
                    <span className="text-red-700"> *</span>
                  </Text>
                  <div className="mt-[1px]">
                    <Tooltip text={t('plan:product-tooltip') as string} />
                  </div>
                </div>
            )}
              data={productsData?.map((product) => product.name) || []}
              selectedOption={field.value?.name || ''}
              onCreate={handleProductCreation}
              onSelect={handleProductSelect}
            />
          )
        }}
      />
    </div>
  )
}
