import { Anchor, Text, Title } from '@mantine/core'
import { modals } from '@mantine/modals'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSynchronizeInvoices } from '../../queries'
import SynchronizeModalCard from '../invoice-external-processors/synchronize-modal-card'
import { Path } from '../../../../shared/enums'
import InvoiceSynchronizeParams from '../../../../interfaces/invoice/invoice-synchronize-params'

const useInvoiceSynchronizeModal = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { mutate } = useSynchronizeInvoices()

  return (invoiceIds: string[], invoiceSynchronizeParams: InvoiceSynchronizeParams) => modals.open({
    withCloseButton: true,
    title: <Title order={4} fw={600}>Sincronizar faturas</Title>,
    children: (
      <SynchronizeModalCard
        onContinue={() => mutate(invoiceIds, {
          onSuccess: () => {
            toast.info(
              <Text size="sm">
                {t('invoice:external-processor:sync-alert-modal:description')}
                <Anchor onClick={() => navigate(Path.InvoiceExternalProcessors)} c="violet">
                  {t('invoice:external-processor:sync-alert-modal:processors-page')}
                </Anchor>
              </Text>,
            )
            modals.closeAll()
            invoiceSynchronizeParams.onSuccess()
          },
          onError: () => {
            modals.closeAll()
            if (invoiceSynchronizeParams.onError) invoiceSynchronizeParams.onError()
          },
        })}
        onCancel={() => {
          modals.closeAll()
          if (invoiceSynchronizeParams.onCancel) invoiceSynchronizeParams.onCancel()
        }}
      />
    ),
  })
}

export default useInvoiceSynchronizeModal
