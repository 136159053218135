import { ReactElement } from 'react'
import PageHeader from './page-header-v2'
import Sidebar from '../sidebar'

interface Props {
  children: ReactElement;
  headerButtonText?: string;
  headerButtonOnClick?: () => void;
}

export default function PageLayoutV2({ children, headerButtonText, headerButtonOnClick }: Props) {
  return (
    <div className="w-full min-h-screen bg-white flex">
      <Sidebar />
      <div className="w-full ml-48">
        <PageHeader buttonText={headerButtonText} buttonOnClick={headerButtonOnClick} />
        <div className="w-full max-h-full flex-grow pt-4 px-6 mt-10">
          {children}
        </div>
      </div>
    </div>
  )
}
